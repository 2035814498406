import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { routes } from "src/constants"
import { colors, radius } from "css/theme"
import { btn } from "css/primitives"
import { If } from "src/components"

const modalButton = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    display: block;
    box-shadow: 1px 0 20px 0 black;
    &:hover {
      color: white;
    }
  `,
]

const modalWrapper = css`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: ${colors.modalWrapperBackground};
`

const modal = css`
  background-color: black;
  border-radius: ${radius.medium};
  color: white;
  max-width: 700px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const modalHeading = css``

const modalDescription = css`
  max-width: 460px;
  font-size: 18px;
  line-height: 1.77;
  opacity: 0.75;
  margin-bottom: 30px;
  a {
    color: ${colors.orange};
  }
`

export default function AfterDownloadModal({ open = false, link }) {
  if (!open) {
    return null
  }
  return (
    <div>
      <div css={modalWrapper}>
        <div css={modal}>
          <h2 css={modalHeading}>Thanks!</h2>
          <p css={modalDescription}>
            Hope you find this useful. Browse the rest of our library and
            continue learning.
          </p>
          <If test={link}>
            <p css={modalDescription}>
              In case your download didn't begin, <a href={link}>click here</a>
            </p>
          </If>
          <Link to={routes.library} css={modalButton}>
            Continue Browsing
          </Link>
        </div>
      </div>
    </div>
  )
}
